.landingPage-wrapper{
  position:absolute;
  top:30%;
  left:25vw;
  display: flex;
  flex-direction: column;
  gap:5px;
  z-index: 10;
  width: auto;
  height: auto;
  @media screen and (max-width: 768px) {
    top: 120px;
    .aboutMe-wrapper {
      h1{
        font-size: 42px;
        font-weight: bold;
        white-space: nowrap;
      }
      p{
        font-size: 30px;
      }
    }
    .wrap{
      font-size: 25px;
    }
  }
}

.aboutMe-wrapper {
    display: flex;
    justify-content: center;

    width: 50vw;
    flex-direction: column;

    h1{
      font-size: 56px;
      font-weight: bold;
      white-space: nowrap;
    }
  p {
  margin:0;
    font-size: 40px;

  }

    h1 span, p span {
      display: inline-block;
      opacity: 0;
    }

    h1.in-view span, p.in-view span {
      animation: flyIn 1s ease forwards;
    }

    h1.fade-out span, p.fade-out span {
      animation: fadeOut 0.5s ease-out forwards;
    }

    @keyframes flyIn {
      0% {
        opacity: 0;
        transform: translateY(-100%);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @keyframes flyOut {
      0% {
        opacity: 1;
        transform: translateY(0);
      }
      100% {
        opacity: 0;
        transform: translateY(-20px);
      }
    }
    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
}
.scroll-toAbout {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #2980b9;
  }
}
.typing-wrapper{
  width: auto;
  z-index: 10;
  transition: opacity 1.5s ease-in-out;
  p{
    margin-left:0;
  }
}
.wrap {
  overflow: hidden;
  border-right: .1em solid white;
  white-space: nowrap;
  font-size: 48px;
  font-weight: bold;
  width: auto;
  letter-spacing: 0.05em;
  animation: blink-caret 0.75s infinite;
}
.typing-wrapper.fade-in {
  opacity: 1;
  transition-duration: 2s;
}

.typing-wrapper.fade-out {
  opacity: 0;
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}