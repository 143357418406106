
.animation-container-columns {
  position: fixed;
  top:0;
  z-index: 999;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap:0;
  height: 100vh;
  .column{
    height: 0;
    width: 20%;
    background:var(--background-color);
    animation: increaseAndReduceHeight 1.5s ease-in-out forwards;
  }
  .column:nth-child(1) {
    animation-delay: 0.3s;
  }

  .column:nth-child(2) {
    animation-delay: 0.2s;
  }

  .column:nth-child(3) {
    animation-delay: 0.1s;
  }

  .column:nth-child(4) {
    animation-delay: 0.2s;
  }

  .column:nth-child(5) {
    animation-delay: 0.3s;
  }
}

@keyframes increaseAndReduceHeight {
  0% {
    height: 0;
    border-radius: 0 0 50% 50%;
  }
  30% {
    height: 100vh;
    border-radius: 0 0 0 0;

  }
  70% {
    height: 100vh;
    border-radius: 0 0 0 0;
;
  }
  100%{
    height: 0;
    border-radius: 0 0 25% 25%;
  }
}