.logo-wrapper-header{
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 12px 12px 12px 24px;
  font-size: 12px;
  white-space: nowrap;
}
.logo-header{
  width:48px ;
  height: 48px;
}

.header-navbar {
  position: fixed;
  display: flex;
  flex-direction: row;
  height: 80px;
  background-color: transparent;
  z-index: 499;
  width: 100vw;
  justify-content: space-between;
  top:0;
}
.menu-desktop-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
  ul {
    list-style: none;
    display: flex;

    li {
      margin-right: 5vw;

      &:hover {
        color: var(--secondary-color);
        transition: 0.5s ease;
      }
    }
  }
}

.menu-mobile-header{
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  padding: 12px 24px 12px 12px;
}
.mobile-menu {
  position: fixed;
  display: none;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 1000;

  ul {
    list-style: none;
    padding: 20px;
    li {
     padding-bottom: 15px;
      text-align: center;
      position: relative;
      background-color: var(--background-color);
      width: 100%;


      &:hover {
        background-color: var(--tertiary-color);
        transition: 2s ;
        a {
          color: var(--secondary-color);
          transition: .5s ease;
        }
        .mobile-menu-element:after {
          width: 0%;
          background: linear-gradient(to right, white, white);
          transition: width 0.5s ease;
        }
      }
      a {
        text-decoration: none;
        color: white;
        font-size: 18px;
        display: block;
        padding: 10px;
        transition: background-color 0.3s ease;
      }
    }
    li:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    li:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.mobile-menu-element{
  &:after {
    content: "";
    position: absolute;
    bottom:30%;
    left: 50%;
    width: 50%;
    height: 1px;
    background-color: white;
    transform: translateX(-50%);
    transition: background 0.5s ease;
  }
}

@media (max-width: 768px) {
  .menu-desktop-header {
    display: none;
  }

  .menu-mobile-header {
    display: flex;
    flex-direction: row;
    gap:5vw;
    }

  .mobile-menu {
    display: block;
  }
  .dropdown-button {
    position: relative;
  }
  @media (max-width: 275px) {
    .logo-wrapper-header a {
      display: none;
    }
  }
}

.dropdown-container {
  position: relative;
  display: inline-block;
  text-align: center;
  div{
    align-items: center;
    justify-content: center;
    display:flex;
  }
}
.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
  box-shadow: 5px 8px 16px 5px rgba(0, 0, 0, 1);
  z-index: 999;
  border-radius: 10px;

  button {
    width: 100%;
    padding: 15px;
    text-align: left;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #fff;
    font-size: 16px;

    &:hover {
      background-color: var(--background-color);
      color: var(--secondary-color);
      transition: 0.7s ease;
    }

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      &::before {
        display: none;
      }
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: white;
    }
  }
}
.dropdown-button {
  background-color: transparent;
  color: white;
  font-size: 20px;
  border: none;
  cursor: pointer;
  padding: 0px;

  &:hover{
    color: var(--secondary-color);
    transition: 0.7s ease;
  }
}
.current-page {
  position: relative;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--primary-color);
    opacity: 0;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    width: 110%;
    height: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 2px 0 0 var(--primary-color);
    transition: transform 0.3s ease-out;
  }

  &.active::before {
    opacity: 1;
    animation: jumpAndLand 0.6s forwards;
  }

  &.active::after {
    animation: shrinkAndExpand 0.6s forwards;
  }
  &.prev::after {
    animation: shrinkToEnd 0.3s forwards;
  }

  @keyframes shrinkToEnd {
    0% {
      transform: translateX(-50%) scaleX(1);
    }
    100% {
      transform: translateX(-50%) scaleX(0);
    }
  }

  @keyframes shrinkAndExpand {
    0% {
      transform: translateX(-50%) scaleX(0);
    }
    100% {
      transform: translateX(-50%) scaleX(1);
    }
  }

  @keyframes jumpAndLand {
    0% { bottom: -15px; }
    50% { bottom: -35px; }
    100% { bottom: -10px; }
  }
}