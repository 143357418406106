.project-section{
  position: absolute;
  top:calc(300vh + 80px);
  background: transparent;
  z-index: 5;
  height: calc(100vh - 115px);
  width: 100%;
}

.project-section.appear {
  animation: appear 1s forwards;
}

.project-section.disappear {
  animation: disappear 0.3s forwards;
}
.projects-container{
  display: flex;

  justify-content: center;

  gap:10px;
  height: 100%;
  position: relative;
  h1{
    font-weight: bold;
    font-size: 32px;
    text-align: center;
  }
}
.projects-spinner-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.project-next-left{
  padding-left: 5%;
}
.project-next-right{
  padding-right: 5%;
}

.project-next-right,
.project-next-left{

  button{
    background: transparent;
    border: transparent;
    color:#fff;
    svg{
      width: 35px;
      height: 35px;
    }
  }
}

.project-slider-wrapper{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.project-slider{
  width: 50%;
  height: 75%;
  #ul-slider {
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
    height: 100%;
    width: 100%;
    padding:0;
  }
  .single-project{
    list-style: none;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 1s ease-in-out;
  }
}
.single-project.position-0{
  transform: translateX(0);
}
.single-project.position-1{
  transform: translateX(100%) translateZ(-500px) rotateY(30deg);
  pointer-events: none;
}
.single-project.position-2{
  transform:translateZ(-1000px);
  pointer-events: none;
}
.single-project.position-3{
  transform: translateX(-100%) translateZ(-500px) rotateY(-30deg);
  pointer-events: none;
}

@keyframes appear {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes disappear {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

//The Single Project

.project{
  width: 100%;
  height: 100%;
  background: var(--tertiary-color);
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
  0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  line-height: 1.4;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  &:hover{
    transform: scale(1.02);
    }
}

.project-details {
  padding: 2em 5%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  p {
    margin-top: 25px;
    font-size: 26px;
    text-align: justify;
    white-space: pre-wrap;
  }
  h2,h3{
    font-size: 32px;
  }
  .projects-head-wrapper{
    height:75%;
    display: block;
    overflow-x:hidden;
  }
  .github-Repo{
    display: flex;
    gap:10px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    a{
      width: 100%;
      border:1px solid #fff;
      border-radius: 10px;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      gap:10px;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in-out;
      letter-spacing: 1px;
      font-size: 26px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
      0 6px 6px rgba(0, 0, 0, 0.23);
      svg{
        height: 100%;
        aspect-ratio: 1;
      }
      &:hover{
        color:var(--primary-color);
      }
    }
  }
  .project-tags{
    height: 25%;
    ul{
      list-style-type: none;
      margin:0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }
    li{
      margin: 0 0.25em 0.25em 0;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
      0 6px 6px rgba(0, 0, 0, 0.23);
      border-radius: 50px;
      display: block;
      padding: 0.3125em 1.25em;
      border: 1px solid #fff;
    }
  }
}
@media only screen and (max-width: 350px) {
  .project-slider-wrapper{
    transform: scale(0.85);
  }
  .project-slider{
    width: 80%;
  }
  .project-details {
    h2,h3{
      font-size: 16px;
    }
    .github-Repo a{
      font-size: 10px;
    }
    p{
      font-size: 10px;
      text-align: left;
    }}
  .projects-container h1{
    font-size: 14px;
  }
}
@media only screen and (min-width: 351px) and (max-width: 1050px) {
  .project-slider{
    width: 95%;
  }
  .project-details {
    h2,h3{
      font-size: 20px;
    }
    .github-Repo a{
      font-size: 14px;
    }
    p{
    font-size: 14px;
      text-align: left;
  }}
  .projects-container h1{
    font-size: 18px;
  }
}
@media only screen and (min-width: 1051px) and (max-width: 1655px) {
  .project-slider {
    width: 75%;
  }

  .project-details {
    h2 {
      font-size: 26px;
    }
    h3{
      font-size: 20px;
    }
  }

  .project-details p {
    font-size: 20px;
  }
  .github-Repo a{
    font-size: 20px;
  }
}