.about-page {
  position: absolute;
  top:calc(100vh + 80px);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: calc(100vh - 80px - 35px);
}

.about-header {
  font-size: 2em;
  color: #fff;
  margin-bottom: 20px;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  padding-left: 35px;
}
.about-header.fade-in {
  opacity: 1;
}
.about-header.fade-out {
  opacity: 0;
}

.about-text {
  width:calc(100% - 25px);
  color: #fff;
  line-height: 1.5;
  text-align: left ;
  padding-left: 25px;
  font-size: 1.5em;
  letter-spacing: 0.1em;
  max-height: 50vh;
  overflow-x: hidden;
  span {
    opacity: 0;
    position: relative;
  }
}

.content-and-Image-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60%;
}
.about-me-image-wrapper{
  justify-content: center;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  transform:scale(1);
  position: relative;
  /*&::before {
    content: "Hover to Reveal";
    position: absolute;
    top: 50%;
    font-size: 1.2em;
    color: #fff;
    opacity: 0; visibility: visible;
    transition: opacity 0.5s ease-in-out ;
    animation: bounce 2s infinite;
    z-index: 22;
  }

  &.fade-in::before {
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out 1.5s forwards, bounce 2s infinite;;
  }

  &.fade-out::before {
    opacity: 0;
  }

  &:hover::before {
    opacity: 0;
    visibility: hidden;
    transition-delay: 0.5s;
  }*/
}

.background-image{
  position: absolute;
  top: -2.5%;
  left: 24%;
  height: 110%;
  width: 50%;
  z-index: 1;
  border-radius:49% 51% 24% 76% / 60% 57% 43% 40% ;
  // background: linear-gradient(45deg,  var(--quaternary-color) 20%, var(--tertiary-color) 70%);
  // box-shadow: 0 0 15px rgba(0, 0, 0, 0.25),
  // inset 0 0 15px rgba(0, 0, 0, 0.25);
  transition: transform 1s ease-in-out;

  &.scale-up {
    animation: scale-up 2s forwards ease-in-out;
  }
  &.scale-down {
    animation: scale-down 2s forwards ease-in-out;
  }
}

.about-me-content{
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 0.5s ease-in;

}
.about-me-content.fade-in{
  opacity: 1;
}

.about-me-content.fade-out{
  opacity: 0;
}
.about-me-image {
  transition: transform 1s ease-in-out, filter 0.5s ease-in-out;
  height: 100%;
  width: auto;
  // filter: blur(10px);
  z-index: 2;
  mask-image: radial-gradient(
                  circle at center top,
                  white 0%,
                  white 80%,
                  transparent 90%,
                  transparent 100%
  );
  position: relative;


  &.scale-up {
    animation: scale-up 2s forwards ease-in-out;
  }
  &.scale-down {
    animation: scale-down 2s forwards ease-in-out;
  }

 /* &:hover {
    filter: blur(0);
  }*/
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes scale-down {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    top: -5px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
@keyframes leave {
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
.social-media-wrapper:last-child {
  position: relative;
  flex-grow: 1;
  transform:scale(1);
  display: flex;
  align-items: center;
  justify-content: center;
  &.scale-up {
    animation: scale-up 2s forwards ease-in-out;
  }
  &.scale-down {
    animation: scale-down 2s forwards ease-in-out;
  }
  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0 3.33px;
  }

  .fa-brands {
    color: #262626;
    font-size: 26.67px;
    line-height: 43.33px;
    transition: 1s;
    padding-right: 9.33px;

    svg {
      vertical-align: middle;
    }
  }

  a {
    width: 150px;
    height: 53.33px;
    background: #fff;
    text-decoration: none;
    display: block;
    text-align: left;
    padding-left: 13.33px;
    transform: rotate(-30deg) skew(25deg) translate(0, 0);
    box-shadow: -13.33px 13.33px 6.67px #000000;
    transition: 1s;
  }

  a span {
    color: #262626;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 15px;
    letter-spacing: 3.33px;
    transition: 1s;
    font-weight: bold;
  }

  a:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 13.33px;
    background: #b1b1b1;
    top: 6.67px;
    left: -13.33px;
    transform: rotate(0deg) skewY(-45deg);
    transition: 1s;
  }

  a:after {
    content: '';
    position: absolute;
    height: 13.33px;
    width: 100%;
    background: #b1b1b1;
    bottom: -13.33px;
    left: -6.67px;
    transform: rotate(0deg) skewX(-45deg);
    transition: 1s;
  }

  a:hover {
    transform: rotate(-30deg) skew(25deg) translate(8.89px, -10px);
    box-shadow: -26.67px 26.67px 26.67px #000;

  }

  li:hover .fa-brands,
  li:hover span {
    color: #fff;
  }

  li:hover:nth-child(1) {
    & a {
      background: var(--primary-color);

      &::before {
        background: var(--primary-color);
        opacity: 0.7;
      }
      &::after {
        background: var(--primary-color);
        opacity: 0.6;
      }
    }
  }

  li:hover:nth-child(2) {
    & a {
      background: var(--secondary-color);

      &::before {
        background: var(--secondary-color);
        opacity: 0.7;
      }
      &::after {
        background: var(--secondary-color);
        opacity: 0.6;
      }
    }
  }

  li:hover:nth-child(3) {
    & a {
      background: var(--tertiary-color);

      &::before {
        background: var(--tertiary-color);
        opacity: 0.7;
      }
      &::after {
        background: var(--tertiary-color);
        opacity: 0.6;
      }
    }
  }
  li:hover:nth-child(4) {
    & a {
      background: var(--quaternary-color);

      &::before {
        background: var(--quaternary-color);
        opacity: 0.7;
      }
      &::after {
        background: var(--quaternary-color);
        opacity: 0.6;
      }
    }
  }
}

@media only screen and (max-width: 434px) {
  .about-me-image-wrapper,
  .about-me-image {
    display: none;
  }
  .about-text{
    font-size: 1em;
  }
  .about-header{
    width:calc(100% - 35px);
  }
  .about-me-content {
    width:calc(100% - 25px);
    display: flex;
    flex-direction: column;
    gap: 10vh;
  }

  .social-media-wrapper {
   ul{
     li{
      display: none;
     }
   }
  }
}

@media only screen and (min-width: 435px) and (max-width: 767px) {
  .about-me-image-wrapper,
  .about-me-image {
    display: none;
  }
  .about-text{
    font-size: 1.2em;
  }
  .about-header{
    width:calc(100% - 35px);
  }
  .about-me-content {
    width:calc(100% - 25px);
    display: flex;
    flex-direction: column;
    gap: 10vh;
  }

  .social-media-wrapper {
    ul{
      transform: scale(0.5);
      transition: transform 1s ease-in-out;
    }
  }
}

@media only screen and (min-width: 1296px) and (max-width: 1900px) {
  .social-media-wrapper {
    ul{
      transform: scale(0.9);
      transition: transform 1s ease-in-out;
    }
  }
}
@media only screen and (min-width: 1152px) and (max-width: 1295px) {
  .social-media-wrapper {
    ul{
      transform: scale(0.8);
      transition: transform 1s ease-in-out;
    }
  }
}
@media only screen and (min-width: 1008px) and (max-width: 1151px) {
  .social-media-wrapper {
    ul{
      transform: scale(0.7);
      transition: transform 1s ease-in-out;
    }
  }
  .about-me-image-wrapper {
    transform: scale(0.7);

  }
}
@media only screen and (min-width: 864px) and (max-width: 1007px) {
  .social-media-wrapper {
    ul{
      transform: scale(0.6);
      transition: transform 1s ease-in-out;
    }
  }
  .about-me-image-wrapper {
    transform: scale(0.7);
  }
}

@media only screen and (min-width: 768px) and (max-width: 863px) {
  .social-media-wrapper {
    ul{
      transform: scale(0.5);
      transition: transform 1s ease-in-out;
    }
  }
  .about-me-image-wrapper {
    transform: scale(0.7);
  }
}