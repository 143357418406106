.contact-section {
  position: absolute;
  top:calc(400vh + 80px);
  background: transparent;
  z-index: 5;
  height: calc(100vh - 200px);
  width: 100%;

}

.contact-section.appear {
  animation: appear 1s forwards;
}

.contact-section.disappear {
  animation: disappear 0.3s forwards;
}

@keyframes appear {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes disappear {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
.contact-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.responding-message{
  position: absolute;
  top:30%;
  left:25%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height:100%;
  opacity: 0;

}
.responding-message.appear {
  animation: appear 1s forwards;
  display: flex;
}

.responding-message.disappear {
  animation: disappear 0.3s forwards;
  display: none;
}

h2 {
  color: #fff;
  letter-spacing: 2px;
  font-size: 32px;
  margin:0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

p {
  color: #fff;
  letter-spacing: 2px;
  font-size: 24px;
  margin:10px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:10px;
  height:100%;
  opacity: 0;
}
.contact-form.appear {
  animation: appear 1s forwards;
}

.contact-form.disappear {
  animation: disappear 0.3s forwards;
}

.form-name,
.form-email{
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 50px);
  padding: 5px;

  label{
    height: 100%;
    width: 100%;
    max-height: 50px;
    min-height: 16px;

    input{
      height: 90%;
      width: 70%;
      font-size: 32px;
      padding-left: 25px;
      padding-right: 25px;
      max-width: 600px;
      border-radius: 25px;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }
}

.form-message{
  padding: 5px;
  height: 100%;
  width: calc(100% - 50px);
  textarea{
    border-radius: 25px;
    width: 70%;
    max-width: 600px;
    height: 75%;
    padding: 25px;
    font-family: 'minimal', 'sans-serif';
    font-size: 32px;
    resize: none;
  }

}

.form-submit{
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.button-wrapper-contact{
  width: 50%;
  height: 50%;
  display: flex;
  margin-top: -25px;
  justify-content: center;
  align-items: flex-start;
}
.button-contact-download {
  background: linear-gradient(to right, var(--secondary-color), var(--tertiary-color));
  width: auto;
  height: 75px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: none;
  position: relative;
  animation: neon-border 1.5s infinite alternate;
  z-index: 15;
  transition: all 0.5s ease-in-out;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 25px;

  h1 {
    font-size: 32px;
    color: #fff;
    letter-spacing: 2px;
    font-family: 'minimal', 'sans-serif';
    text-shadow: 1px 1px #666;
  }

  &:hover {
    animation: none;
    transform: translateY(3px) scale(0.97);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    box-shadow: 0 0 5px 0 rgba(90, 135, 194, 0.8), 0 0 10px 2px rgba(90, 135, 194, 0.6);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  &:hover::before {
    opacity: 1;
  }

  @media (max-width: 768px) {
    transform: scale(0.5);

    &:hover {
      transform: translateY(3px) scale(0.45);
    }
  }

  @media (min-width: 769px) and (max-width: 1000px) {
    transform: scale(0.6);

    &:hover {
      transform: translateY(3px) scale(0.55);
    }
  }

  @media (min-width: 1001px) and (max-width: 1500px) {
    transform: scale(0.7);

    &:hover {
      transform: translateY(3px) scale(0.65);
    }
  }

  @media (min-width: 1501px) and (max-width: 2000px) {
    transform: scale(0.8);

    &:hover {
      transform: translateY(3px) scale(0.75);
    }
  }

  @media (min-width: 2001px) and (max-width: 2560px) {
    transform: scale(0.9);

    &:hover {
      transform: translateY(3px) scale(0.85);
    }
  }

  @media (max-width: 480px) {
    transform: scale(0.4);

    &:hover {
      transform: translateY(3px) scale(0.35);
    }
  }

  @media (max-width: 320px) {
    transform: scale(0.3);

    &:hover {
      transform: translateY(3px) scale(0.25);
    }
  }

}

@keyframes neon-border {
  0% {
    box-shadow: 0 0 10px 0 rgba(90,135,194, 0.8), 0 0 20px 5px rgba(90,135,194, 0.6);
  }
  100% {
    box-shadow: 0 0 20px 0 rgba(90,135,194, 0.8), 0 0 30px 5px rgba(90,135,194, 0.6);
  }
}

.button-contact-download.deactivated {
  opacity: 0.5;
}

@media only screen and (min-width: 769px) and (max-width: 1068px) {

  p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .responding-message h2 {
    font-size: 20px;
  }
  p {
    font-size: 12px;
  }
}
@media only screen and (min-height: 701px) and (max-height: 1050px){
  .form-name,
  .form-email,
  .form-message{
   label{
     input {
       font-size: 24px;
     }
    }
    textarea{
      font-size: 24px}
  }
}
@media only screen and (max-height: 700px){
  .form-name,
  .form-email,
  .form-message{
    label{
      input {
        font-size: 18px;
      }
    }
    textarea{
      font-size: 18px}
  }
}