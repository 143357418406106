.pagewrapper-nextButton {
  position: fixed;
  bottom: 15px;
  right: 50%;
  height: auto;
  transform: translateX(50%);
  z-index: 555;
  background: transparent;
  border:none;
}

.about-next-button{
  background: rgba(0,0,0,0.5);
  width: 30px;
  height: 30px;
  border: none;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  border-radius: 50%;
  transition: transform 0.5s ease, background 1s ease;

  &:hover {
    transform: translateX(50%) translateY(5px);
  }
}
.pagewrapper-prevButton {
  position: fixed;
  top: 20px;
  right: 50%;
  transform: translateX(50%);
  z-index: 555;
  background: transparent;
  border:none;
}


.custom-arrow {
  font-size: 24px;
  padding: 8px;
  cursor: pointer;
}

.larger-arrow {
  font-size: 32px;
}

.smaller-arrow {
  font-size: 20px;
}