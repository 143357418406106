.custom-bars-icon {
  width: 25px;
  height: 21px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap:3px;
  justify-content: center;

  div {
    height: 3px;
    background-color: #fff;
    transition: width 0.5s ease, background-color 0.5s ease;
    border-radius: 1.5px;
    margin-left: auto;
    &:nth-child(1) {
      width:100%;
    }
    &:nth-child(2) {
      width: 75%;
    }

    &:nth-child(3) {
      width: 50%;
    }
  }

  &.active {
    div {
      width: 100%;
      background-color: var(--secondary-color);
    }
  }

  &:hover {
    div {
      width: 100%;
      background-color: var(--secondary-color);
    }
  }
}