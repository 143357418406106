//GENERAL
//LANDINGPAGE IS ONLY USING GENERAL SYTYLE
.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: -1;
  perspective: 1000px;

}

.background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  mask-image: radial-gradient(
                  white 0%,
                  white 30%,
                  transparent 80%,
                  transparent
  );
}

.circle-container {
  $particleNum: 200;
  $particleColor: hsl(180, 100%, 80%);

  position: absolute;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(
                    hsl(180, 100%, 80%),
                    hsl(180, 100%, 80%) 10%,
                    hsla(180, 100%, 80%, 0) 56%
    );

    animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

    @keyframes fade-frames {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.7;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes scale-frames {
      0% {
        transform: scale3d(0.4, 0.4, 1);
      }

      50% {
        transform: scale3d(2.2, 2.2, 1);
      }

      100% {
        transform: scale3d(0.4, 0.4, 1);
      }
    }
  }

  $particleBaseSize: 8;

  @for $i from 1 through $particleNum {
    &:nth-child(#{$i}) {
      $circleSize: random($particleBaseSize);
      width: $circleSize + px;
      height: $circleSize + px;

      $startPositionY: random(10) + 100;
      $framesName: "move-frames-" + $i;
      $moveDuration: 28000 + random(9000) + ms;

      animation-name: #{$framesName};
      animation-duration: $moveDuration;
      animation-delay: random(37000) + ms;

      @keyframes #{$framesName} {
        from {
          transform: translate3d(random(100) + vw, $startPositionY + vh, 0);
        }

        to {
          transform: translate3d(random(100) + vw, -$startPositionY - random(30) + vh, 0);
        }
      }

      .circle {
        animation-delay: random(4000) + ms;
      }
    }
  }
}


//ABOUTPAGE

section .air{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url('../../../public/assets/backgrounds/wave.png');
  background-size: 1000px 100px
}
section .air.air1{
  animation: wave 30s linear infinite;
  z-index: 1000;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
}
section .air.air2{
  animation: wave2 15s linear infinite;
  z-index: 999;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;
}
section .air.air3{
  animation: wave 30s linear infinite;
  z-index: 998;
  opacity: 0.2;
  animation-delay: -2s;
  bottom: 15px;
}
section .air.air4{
  animation: wave2 5s linear infinite;
  z-index: 997;
  opacity: 0.7;
  animation-delay: -5s;
  bottom: 20px;
}
@keyframes wave{
  0%{
    background-position-x: 0px;
  }
  100%{
    background-position-x: 1000px;
  }
}
@keyframes wave2{
  0%{
    background-position-x: 0px;
  }
  100%{
    background-position-x: -1000px;
  }
}
//warn versuch, sah beschissener aus als vorher also bleiben wir bei empty...
.about-background-container {
  display: flex;
  height: 400vh;
  background: radial-gradient(ellipse at bottom, var(--background-color) 0%, #030617 100%);
}
.empty-background-container {
  height: 100%;
  background: transparent;
}
.background-preset {
  animation:slide 12s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, var(--tertiary-color) 50%, var(--background-color) 50%);
  mask-image: radial-gradient(
                  white 0%,
                  white 30%,
                  transparent 80%,
                  transparent
  );
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:0;
}

.background-preset-reverse {
  animation-direction:alternate-reverse;
  animation-duration:15s;
}

.background-preset-slowest {
  animation-duration:18s;
}


@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

//DOWNLOAD ANIMATION

@function shadowValue($radius,$cosV,$sinV,$bokeh,$lineColor){
  $value: '#{($radius*$cosV)}px #{($radius*$sinV)}px #{$bokeh}px #{$lineColor}';
  $value: '#{$value} ,#{(($radius - 1)*$cosV)}px #{(($radius - 1)*$sinV)}px #{$bokeh}px #{$lineColor}';
  $radius: $radius / 1.8;
  $value: '#{$value} ,#{($radius*$cosV)}px #{($radius*$sinV)}px #{$bokeh}px #{$lineColor}';
  $value: '#{$value} ,#{(($radius - 1)*$cosV)}px #{(($radius - 1)*$sinV)}px #{$bokeh}px #{$lineColor}';
  $radius: $radius / 2;
  $value: '#{$value} ,#{($radius*$cosV)}px #{($radius*$sinV)}px #{$bokeh}px #{$lineColor}';
  $radius: $radius / 10;
  $value: '#{$value} ,#{($radius*$cosV)}px #{($radius*$sinV)}px #{$bokeh}px #{$lineColor}';
@return unquote($value)
}

@function ringEffect($n, $radius, $bokeh, $lineColor) {
  $value: '0px 0px #FFF';
  @for $i from 0 through $n {
    $radian: (360 / $n) * $i * 3.141592653589793 / 180;
    $value: '#{$value} , #{shadowValue($radius, cos($radian), sin($radian), $bokeh, $lineColor)}';
  }
  @return unquote($value);
}

$num: 20;

@for $i from 0 through $num {
  .fire#{$i} {
    $rand1: random(100);
    $rand2: random(100);
    top: #{'#{$rand1}%'};
    left: #{'#{$rand2}%'};

    $color: unquote('hsl(#{random(240)},70%,70%)');
    .ring_1 {
      width: 1px;
      height: 1px;
      box-shadow: ringEffect(30, 30, 0, $color);
    }

    .ring_2 {
      width: 1px;
      height: 1px;
      box-shadow: ringEffect(30, 30, 2, $color);
    }
  }
}

.fireworks {
  opacity: 0;
  position: absolute;
}

$basetime: 0;

@for $i from 0 through $num {
  $basetime: $basetime * 2;
  @if $basetime == 0 {
    $basetime: 3;
  }
  .pattern#{$i} {
    animation: pattern#{$i} #{random(8) + 2}s ease infinite;
  }

  @keyframes pattern#{$i} {
    $scale: random(7);
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0;
      transform: scale(0.1);
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(#{$scale});
      opacity: 0;
    }
  }
}
