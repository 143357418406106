:root {
  --primary-color: #5A87C2;
  --secondary-color: #2B4D7F;
  --tertiary-color: #1B384E;
  --quaternary-color: #0B2345;
  --background-color: #021027;

  color:#fff;
}
* {
  font-family: 'minimal', sans-serif
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

body {
  scrollbar-width: thin;
  background-color: var(--background-color);
}

a {
  text-decoration: none;
  color: inherit;
}
.overflow-y{
  overflow-y: scroll;
}